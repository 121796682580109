import React, { useEffect, useState } from 'react';
import './../scss/index.scss';
import './../scss/vars-and-mixins.scss';
import './../app/components/audio/audio.scss';
import './../app/components/canvas/canvas.scss';
import './../app/components/discovery/index.scss';
import './../app/components/discovery/editor/index.scss';
import './../app/components/discovery/finding-editor/finding-editor.scss';
import './../app/components/discovery/discovery-navigation.scss';
import './../app/containers/dashboard/style.scss';
import './../app/containers/app/app.scss';
import './../app/containers/wiki/beautifulDnDStyles.css';
import './../app/components/discovery/interview/interview-list.scss';
import './../app/components/general/customSearch.scss'
import '@uppy/core/dist/style.css';
import '@uppy/webcam/dist/style.css';
import '@uppy/url/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/audio/dist/style.css';
import '@uppy/informer/dist/style.css';
import 'react-h5-audio-player/lib/styles.css';
import '@uppy/file-input/dist/style.css';
import 'react-select-search/style.css';
import 'remirror/styles/all.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DiscoverySessionState, discoverySessionAtom } from '../app/stores/jotai/discovery-session';
import IwSplashScreen from "./../app/components/app/IwSplashScreen";
import { useAtom } from 'jotai';
import LogRocket from 'logrocket';
import MaintenanceErrorTimer from './MaintenanceErrorTimer';
LogRocket.init('wvyuji/discovery-platform-v3');
// we should initialize sentry app wide
// Sentry.init({
//   dsn: "https://804ab2fc5f4047d8b52703a30568d21e@o319583.ingest.sentry.io/1809170",
//   replaysSessionSampleRate: 0.1,
//   // If the entire session is not sampled, use the below sample rate to sample
//   // sessions when an error occurs.
//   replaysOnErrorSampleRate: 1.0,
//   integrations: [new Sentry.Replay()]
// });
function DApp({ Component, pageProps }) {
  const [discoverySession, setDiscoverySession] = useAtom(discoverySessionAtom);
  const [splashScreenShowCount, setSplashScreenShowCount] = useState<number>(1);
  const startMaintenanceTime = new Date(process.env.NEXT_PUBLIC_MAINTENANCE_START_TIME).getTime()
  const endMaintenanceTime = new Date(process.env.NEXT_PUBLIC_MAINTENANCE_END_TIME).getTime()
  const currentUTCTime = new Date(new Date().toISOString()).getTime();
  useEffect(() => {
    // Remove the server-side injected CSS.
    try {
      const jssStyles = document.querySelector('#jss-server-side');
      if (jssStyles) {
        //@ts-ignore
        jssStyles.parentElement.removeChild(jssStyles);
      }
    }
    catch (e) {
      
    }
  }, []);
  useEffect(() => {
    if (
      discoverySession.state === DiscoverySessionState.loading_org &&
      splashScreenShowCount <= 1
    ) {
      setSplashScreenShowCount((preCount) => preCount + 1);
    }
  }, [discoverySession.state]);
  
  if (
    startMaintenanceTime <= currentUTCTime &&
    currentUTCTime <= endMaintenanceTime &&
    startMaintenanceTime <= endMaintenanceTime
  ) {
    return <MaintenanceErrorTimer />;
  }
  return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
          <IwSplashScreen visible={splashScreenShowCount <= 1} />
  
          {/* Wait for user data */}
          <Component
              sx={{ backgroundColor: "#1c445b" }}
              {...pageProps}
          />
      </LocalizationProvider>
  );
}
export default DApp
