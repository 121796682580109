import React from "react"
import { toast } from "sonner"
import CloseIcon from "@mui/icons-material/Close"
import { Stack, Typography } from "@mui/material"
import { CSSProperties } from "react"
export const toastStyle: CSSProperties = {
  width: "auto",
  whiteSpace: "nowrap"
}

export const renderErrorToast = (
  message: string,
  onDismiss?: Function
) => {
  const id = toast.error(
    <Stack
      direction="row"
      spacing={"4px"}
      sx={{ alignItems: "center", cursor: "pointer" }}
    >
      <Typography sx={{ fontSize: "0.875rem" }}>{message}</Typography>
      <CloseIcon onClick={() => {
        toast.dismiss(id); 
        if (onDismiss) onDismiss();
      }} />
    </Stack>,
    { style: { ...toastStyle }, duration: Infinity }
  );

  return id; 
};

